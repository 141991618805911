import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { BookedSlotProps } from "../../../components/src/TimeSlothCard";
import React from "react";
export interface Reservation {
  id: string;
  type: string;
  attributes: {
    reservation_date: string;
    drop_off_time: string;  
    childrens: Array<{
      full_name: string;
      image: string;         
      age: number;
      color: string;      
      hour: string;   
    }>;
    care_taker: CareTaker | null;
  };
}

export interface GroupedItems {
  [hours: string]: Reservation[];
}

interface AgeLegend {
  infant: number;
  young_toddler: number;
  older_toddler: number;
  preschool: number;
  young_school_age: number;
  older_school_age: number;
}

type AgeGroup = 'infant' | 'young_toddler' | 'older_toddler' | 'preschool' | 'young_school_age' | 'older_school_age';
const RATIOS: AgeLegend = {
  infant: 4,
  young_toddler: 5,
  older_toddler: 6,
  preschool: 10,
  young_school_age: 12,
  older_school_age:15,
};
interface CareTaker {
  full_name: string;
  image: string | null; 
}

interface Location {
  id: number;
  latitude: number | null;
  longitude: number | null;
  van_id: number | null;
  address: string;
  title: string;
  duration_id: number;
}
const configJSON = require("./config");

interface ReservationsResponse {
  upcoming: {
    data: Reservation[];
  };
  ongoing_reservations: {
    data: Reservation[];
  };
  completed: {
    data: Reservation[];
  };
  running_late : {
    data: Reservation[];
  }
};

interface PageAction {
  signup: boolean;
  setup_profile: boolean;
  child_profile: boolean;
}

interface CareTakerAttributes {
  first_name: string;
  last_name: string;
  phone_number: number | null;
  gender: string | null;
  email: string;
  member_id: string;
  designation: string;
  date_of_birth: string | null;
  address1: string | null;
  address2: string | null;
  state: string | null;
  city: string | null;
  zipcode: string | null;
  account_id: number;
  created_at: string;
  image: string | null;
  page_action: PageAction;
  is_parent: boolean;
  profile_image_url: string;
}

interface CareTaker {
  id: string;
  type: string;
  attributes: CareTakerAttributes;
}

interface ParentAttributes {
  id: number;
  first_name: string;
  last_name: string;
  full_phone_number: string;
  country_code: string | null;
  phone_number: number;
  email: string;
  activated: boolean;
  device_id: string | null;
  unique_auth_id: string;
  password_digest: string | null;
  created_at: string;
  updated_at: string;
  user_name: string | null;
  platform: string | null;
  user_type: string | null;
  app_language_id: string | null;
  last_visit_at: string | null;
  is_blacklisted: boolean;
  suspend_until: string | null;
  status: string;
  role_id: string | null;
  stripe_id: string;
  stripe_subscription_id: string | null;
  stripe_subscription_date: string | null;
  gender: string | null;
  age: number | null;
  designation: string;
  address1: string;
  address2: string;
  city: string;
  zipcode: string;
  state: string;
  member_id: string;
  page_action: PageAction;
  cancel_reason: string | null;
  parent_id: number | null;
  date_of_birth: string;
  profile_image_url: string;
}

interface Parent {
  id: string;
  type: string;
  attributes: ParentAttributes;
}

interface CareTakers {
  data: CareTaker[];
}

interface Parents {
  data: Parent[];
}
interface Slot {
  id: number;
  start_time: string;
  end_time: string;
  day: string;
  slot: number;
  par_hours_amount: number;
}

interface Location {
  location_id: number;
  location_title: string;
  location_address: string;
  slots: Slot[];
}

interface AvailabilityTimeSlothResponse {
  success: string;
  locations: Location[];
}

interface CareTakerApiResponse {
  care_takers: CareTakers;
  parents: Parents;
} 
type CallIdIterfaceforSendFunction = 
  | "apiDashboardItemCallId"
  | "dashboardApiCallId"
  | "apiGetQueryStrinurl"
  | "apiLocationsCallId"
  | "apiCareTakerCallId"
  | "apiChildreanCallId"
  | "apiCallTimeSlothgetCallId"
  |"apiIncidentCallId";

  export interface KidProfile {
    data: Kid[];
  }
  export interface Inc_Location {
    id: number;
    latitude: number;
    longitude: number;
    van_id: number | null;
    address: string;
    title: string;
    duration_id: number | null;
    min_age: number;
    max_age: number;
}

export interface Inc_Child {
    id: number;
    name: string;
    age: number;
    image: string;
}

export interface Inc_ReportAttributes {
    id: number;
    date: string;
    time: string;
    location: Inc_Location;
    location_in_facility: string;
    incident_type: string;
    subtype: string;
    witness: string;
    description: string;
    action_taken: string;
    notified_date: string;
    notification_mode: string | null;
    escalation: string;
    response: string | null;
    status: string;
    parent_response: string;
    notified_person: string;
    child: Inc_Child;
    documents: string[];
}

export interface Inc_Report {
    date: string;
    id: string;
    type: string;
    attributes: Inc_ReportAttributes;
}

export interface Inc_ReportResponse {
    data: Inc_Report[];
}

  
  // Interface for a single Kid
  export interface Kid {
    id: string;
    type: string;
    attributes: KidAttributes;
  }
  
  // Interface for Kid Attributes
  export interface KidAttributes {
    first_name: string;
    last_name: string;
    gender: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    country: string | null;
    zip_code: string;
    legal_custody: string | null;
    language: string;
    nap_time: string | null;
    is_complete: boolean;
    created_at: string;
    child_profile_image: string;
    age: number;
    date_of_birth: string;
    vaccination_upload_image: string | null;
    allergens: KidAllergen[];
    potty_trainings: KidPottyTraining[];
    solid_and_purees: KidSolidAndPuree[];
    special_note: KidSpecialNote;
    care_taker: KidCareTaker[];
  }
  
  // Interface for Allergens
  export interface KidAllergen {
    allergens_id: number;
    allergens_name: string;
    active_icon: string;
    inactive_icon: string;
  }
  
  // Interface for Potty Training
  export interface KidPottyTraining {
    potty_trainings_id: number;
    potty_trainings_name: string;
    active_icon: string;
    inactive_icon: string;
  }
  
  // Interface for Solid and Purees
  export interface KidSolidAndPuree {
    solid_and_purees_id: number;
    solid_and_purees_name: string;
    active_icon: string;
    inactive_icon: string;
  }
  
  // Interface for Special Note
  export interface KidSpecialNote {
    special_note_id: number;
    special_note_description: string;
  }
  
  // Interface for Caretaker
  export interface KidCareTaker {
    id: number;
    full_name: string;
  }
  const MAX_GROUP_SIZES: { [key in AgeGroup]: number } = {
    infant: 12,
    young_toddler: 12,
    older_toddler: 12,
    preschool: 12,
    young_school_age: 12,
    older_school_age: 15
  };





// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: {
    type: string;
    quantity: string;
  }[];
  totalCandidates: string;
  type: string;
  token: string;
  errorMsg: string;
  loading: boolean;
  reservationData : ReservationsResponse|null;
  locations : Location[] |null;
  selectedLocation : number|undefined|null|string;
  anchorEl:any;
  anchorPosition:any
  selectedDate:string;
  CaretakerData : CareTakerApiResponse |null ;
  ChildreanData : KidProfile | null;
  IncidentData :  Inc_ReportResponse | null;
  TimeSloth : AvailabilityTimeSlothResponse |null
  QrMsg: string;
  TimeSlothData : BookedSlotProps |null;
  OpenDrawerDashboard: boolean;
  SelectedDrawerItemData : Inc_Report | null|Kid;
  SelectedKidProfile: Kid |null;
  OpenIncidentFormInKid : boolean
  SelectedIncidentId : string;
  openSelectedStatus:boolean;
  StaffReq:number|string;
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  apiLocationsCallId:string ="";
  apiCareTakerCallId:string ="";
  apiChildreanCallId : string ="";
  apiIncidentCallId : string =""
  apiQcCosdeCallId : string ="";
  apiCallTimeSlothgetCallId : string ="";
  IncidentApiCallId:string="";
  IncidentPutApiCallId:string="";
  inputRef: React.RefObject<HTMLInputElement>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.inputRef = React.createRef<HTMLInputElement>();

    this.state = {
      type: "",
      dashboardData: [],
      totalCandidates: "",
      errorMsg: "",
      token: "",
      loading: false,
      reservationData:null,
      locations: null, 
      selectedLocation: localStorage.getItem('selectedLocation'),
      anchorEl:"",
      anchorPosition:"",
      selectedDate: localStorage.getItem('selectedDate') || new Date().toLocaleDateString("en-GB"),
      CaretakerData:null,
      ChildreanData:null,
      IncidentData :null,
      TimeSloth: null,
      QrMsg : "",
      TimeSlothData:null,
      OpenDrawerDashboard: false,
      SelectedDrawerItemData : null,
      SelectedKidProfile: null,
      OpenIncidentFormInKid: false,
      SelectedIncidentId:"",
      openSelectedStatus :false,
      StaffReq: 0,
    };
    this.updateLocationState = this.updateLocationState.bind(this);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start 
    this.getLocatations();
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const token = localStorage.getItem('authToken')
    if(token){
      this.setState({token: token})
    }
    else {
      this.props.navigation.navigate('EmailAccountLoginBlock')  
    }
    const webHeader = {token:token};
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     `${webConfigJSON.dashboardGetUrl}?location_id=${this.state.selectedLocation}&date=${this.state.selectedDate}`
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let webErrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (webApiRequestCallId != null) {
        const handleApiResponse = (dataKey: string, dataValue: any, shouldNavigate = true) => {
          if (webResponseJson !== undefined && !webResponseJson.errors) {
            this.setState({ [dataKey]: dataValue } as Pick<S, keyof S>);
            if (dataKey === "locations") {
              localStorage.setItem("locations", JSON.stringify(dataValue));
            }
            this.setState({StaffReq:this.calculateStaffFromReservations(this.state.reservationData)})
          } else if (shouldNavigate) {
            this.props.navigation.navigate("EmailAccountLoginBlock");
          }
        };
      
        switch (webApiRequestCallId) {
          case this.apiDashboardItemCallId:
            handleApiResponse("reservationData", webResponseJson);
            break;
      
          case this.apiLocationsCallId:
            handleApiResponse("locations", webResponseJson.locations);
            break;
      
          case this.apiCareTakerCallId:
            handleApiResponse("CaretakerData", webResponseJson);
            break;
      
          case this.apiChildreanCallId:
            handleApiResponse("ChildreanData", webResponseJson);
            break;
          case this.apiIncidentCallId:
            handleApiResponse("IncidentData", webResponseJson);
            break; 
          case this.IncidentApiCallId:
           
            if (webResponseJson !== undefined && !webResponseJson.errors) {
              this.setState({ QrMsg: "Incident Created Successfully" });
              this.toggleDrawerDashboardState()
              this.sendGetAPIRequest(webConfigJSON.getIncidentList, 'apiIncidentCallId')
            }
           
          break;
          case this.IncidentPutApiCallId:
            if (webResponseJson !== undefined && !webResponseJson.errors) {
              this.toggleDrawerIncidentInKidpage()
              this.setState({ QrMsg: "Incident Status Update Successfully" });
              this.sendGetAPIRequest(webConfigJSON.getIncidentList, 'apiIncidentCallId')
            } else {
              this.setState({ QrMsg: "Error: Please try again." });
            }
          break;
          case this.apiCallTimeSlothgetCallId:
                if (webResponseJson !== undefined && !webResponseJson.errors) {
                    this.setState({TimeSlothData:webResponseJson});
                  }
                else{
                    this.props.navigation.navigate("EmailAccountLoginBlock")
                  }
                break;
          case this.apiQcCosdeCallId:
            if (webResponseJson !== undefined && !webResponseJson.errors) {
              this.setState({ QrMsg: "" });
              this.getDashboardData();
            } else {
              this.inputRef.current?.focus();
              this.setState({ QrMsg: "Error: Please try again." });
            }
            break;
      
          default:
            console.warn("Unhandled API call ID:", webApiRequestCallId);
        }
      }
        
    }

    // Customizable Area End
  }
  // Customizable Area Start

  navigateFun = (data:any) => {
    if(data === "Active Bookings"){
      this.props.navigation.navigate("Dashboard")
    } else if(data === "Caretakers - Adults") {
      this.props.navigation.navigate("DashbordCaretakerScreen")
    } else if(data === "Members - Children"){
      this.props.navigation.navigate("DashboardChildrenScreen")
    } else if(data === "Incidents"){
      this.props.navigation.navigate("DashbordIncident")
    }else {
      this.props.navigation.navigate("EmailAccountLoginBlock")
    }
  }

  formatDate = (timestamp: number): string => {
    const date = new Date(timestamp);
    const options: Intl.DateTimeFormatOptions = { weekday: 'long', month: 'long', day: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const suffix = hours >= 12 ? 'PM' : 'AM';
    const formattedTime = `${hours % 12 || 12}:${minutes} ${suffix}`;
    const day = date.getDate();
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const suffixIndex = (day % 10 <= 3 && day % 100 > 20) ? day % 10 : 0;
    const dayWithSuffix = `${day}${suffixes[suffixIndex]}`;
    return `${formattedDate.replace(day.toString(), dayWithSuffix)} ${formattedTime}`;
  };

  getLocatations(): boolean {
    const token = localStorage.getItem('authToken')
    if(token){
      this.setState({token: token})
    }
    else {
      this.props.navigation.navigate('EmailAccountLoginBlock')  
    }
    const webHeader = {token:token};
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiLocationsCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.locationListGetUrl
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }
  updateLocationState(id:any):void{
    this.setState({selectedLocation:id}, ()=>{
      this.getDashboardData();
    })
  }


  sendGetAPIRequest(urlEndpoint:string, callId:CallIdIterfaceforSendFunction):boolean {
    const token = localStorage.getItem('authToken');
  
    if (token) {
      this.setState({ token: token });
    } else {
      this.props.navigation.navigate('EmailAccountLoginBlock');
      return false;
    }
  
    const webHeader = { token: token };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    // Assign the API call ID for tracking responses
    // this[callId] = webRequestMessage.messageId;
    
    // (this[`${callId as CallIdKeys}`]) = webRequestMessage.messageId;
    (this[callId as CallIdIterfaceforSendFunction]) = webRequestMessage.messageId;

  
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${urlEndpoint}?location_id=${this.state.selectedLocation}&date=${this.state.selectedDate}`
    );
  
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );
  
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType // Assuming the method type is consistent across calls
    );
  
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }





  formatToDDMMMYY(dateString:string) {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    
    const date = new Date(dateString);
    
    // Extract day, month, and year
    const day = String(date.getDate()).padStart(2, '0');
    const month = months[date.getMonth()];
    const year = String(date.getFullYear()).slice(-2); // Get last two digits of the year
    
    return `${day}/${month}/${year}`;
  }

  handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      this.setState({QrMsg:""})
      const inputValue = event.currentTarget.value.trim(); // Get the typed string
      
      this.updateQRCodeState(inputValue); // Call the function to handle the QR code input
      event.currentTarget.value = ''; // Clear the input field
    }
  }

  updateQRCodeState(qrCodeInput: string): void {
    // Prepare the data to be sent
    const qrCodeData = {
      qrcode: qrCodeInput,
    };
    this.setState({QrMsg:"Waiting for Responce"})
    // Call the API with the QR code data
    this.sendQRCodeData(qrCodeData);
  }
  sendQRCodeData(qrCodeData: { qrcode: string }): void {
    const urlEndpoint = webConfigJSON.uploadQR; 
    
  
    const token = localStorage.getItem('authToken');
  
    if (!token) {
      this.props.navigation.navigate('EmailAccountLoginBlock');
      return;
    }
  
    const webHeader = { token: token , 'Content-Type': 'application/json' };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    // Assign the API call ID for tracking responses
    this["apiQcCosdeCallId"] = webRequestMessage.messageId;
  
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      urlEndpoint
    );
  
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );
  
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(qrCodeData) // Add the QR code data to the request body
    );
  
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PATCH' // Assuming the QR code API uses POST method
    );
  
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
  }  
  toggleDrawerDashboardState(){
    this.setState({OpenDrawerDashboard:!this.state.OpenDrawerDashboard})
  }
  toggleDrawerIncidentInKidpage(){
    this.setState({OpenIncidentFormInKid:!this.state.OpenIncidentFormInKid})
  }
  openIncidentDrawer(data:Inc_Report | Kid|null){
    this.setState({SelectedDrawerItemData:data,OpenDrawerDashboard:!this.state.OpenDrawerDashboard,OpenIncidentFormInKid:!this.state.OpenIncidentFormInKid ,SelectedIncidentId:data?.id||""})
  }
  openSelectedKid(data:  Kid){
    this.setState({SelectedKidProfile:data,OpenDrawerDashboard:!this.state.OpenDrawerDashboard})
  }


  createIncidentApiCall = async (formData:FormData , Id?:string) => {
    const requiredFields = [
      "incident[date]",
      "incident[time]",
      "incident[location_in_facility]",
      "incident[incident_type]",
      "incident[subtype]",
      "incident[witness]",
      "incident[description]",
      "incident[action_taken]",
      "incident[notified_person]",
      "incident[notified_date]",
      "incident[escalation]",
      "incident[parent_response]",
  ];
  const missingFields = requiredFields.filter(field => !formData.get(field));

  if (missingFields.length > 0) {
      this.showAlert(
          configJSON.errorTitle,
          configJSON.errorEmptyFields
      );
      return false;
  }
      
    const token = localStorage.getItem('authToken');
    const header = {
        "token": token
    };
    let FormData = formData
    FormData.append("incident[location_id]", String(this.state.selectedLocation));
    if (this.state.SelectedKidProfile) {
      FormData.append("incident[child_profile_id]", this.state.SelectedKidProfile.id);
    }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.IncidentApiCallId = requestMessage.messageId;
    let url= !!Id ? `${webConfigJSON.UpdateIncident}?id=${Id}`  : webConfigJSON.createIncident ;
    let calltype = !!Id ? "PUT" : "POST"
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        url
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        FormData
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        calltype
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
};



 getStatusColor = (status: string): string => {
  switch (status) {
    case "Needs Review":
      return "red";
    case "In Progress":
      return "yellow";
    case "Resolved":
      return "green";
    default:
      return "gray"; 
  }
};

PatchIncidentApiCall = async (formData:any) => {
  const token = localStorage.getItem('authToken');
  const header = {
     "Content-Type": webConfigJSON.dashboarContentType,
      "token": token,
     
  };
  let FormData = formData
  const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  this.IncidentPutApiCallId = requestMessage.messageId;

  requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.UpdateIncident
  );

  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );

  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(FormData)
  );

  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  
  return true;
};

PatchUpdateIncidentApiCall = async (formData:any ,id:string) => {
  const token = localStorage.getItem('authToken');
  const header = {
     
      "token": token,
     
  };
  let FormData = formData
  const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  this.IncidentPutApiCallId = requestMessage.messageId;

  requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${webConfigJSON.UpdateIncident}/?id=${id}`
  );

  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );

  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      FormData
  );

  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  
  return true;
};
selectStatuesIncidentOpenFn=(id?:string)=>{
    this.setState({SelectedIncidentId:id||"",openSelectedStatus:!this.state.openSelectedStatus,anchorEl:id})
  
}
handleCellClick = (event:any) => {
  const cellRect = event.currentTarget?.getBoundingClientRect(); // Get cell position
  this.setState({
    anchorEl: event.currentTarget, 
    anchorPosition: { top: cellRect?.bottom, left: cellRect?.left }, // Set position
  });
};




 getAgeGroup(age: number): AgeGroup {
  if (age < 1) return "infant";
  if (age >= 1 && age < 2) return "young_toddler";
  if (age >= 2 && age < 3) return "older_toddler";
  if (age >= 3 && age < 5) return "preschool";
  if (age >= 5 && age < 8) return "young_school_age";
  return "older_school_age";
}

 calculateStaffFromReservations(reservationsData: ReservationsResponse | null): number {
  if (!reservationsData) return 0;
  
  const childrenCount: AgeLegend = {
    infant: 0,
    young_toddler: 0,
    older_toddler: 0,
    preschool: 0,
    young_school_age: 0,
    older_school_age: 0
  };
  
  const relevantCategories: (keyof ReservationsResponse)[] = ["ongoing_reservations", "completed", "running_late"];
  let totalChildren = 0;
  
  relevantCategories.forEach(category => {
    reservationsData[category].data.forEach(booking => {
      booking.attributes.childrens.forEach(child => {
        const ageGroup = this.getAgeGroup(child.age);
        childrenCount[ageGroup] += 1;
        totalChildren += 1;
      });
    });
  });
  
  return this.calculateStaffRequirements(childrenCount, totalChildren);
}

calculateStaffRequirements(children: AgeLegend, totalChildren: number): number {
  // Rule 1: At least 1 staff person must be present when 4 or fewer children are in care
  if (totalChildren <= 4) {
    // We still need to check if ratio requirements demand more staff
    let requiredStaffByRatio = 0;
    for (const [ageGroup, count] of Object.entries(children)) {
      if (count > 0) {
        requiredStaffByRatio = Math.max(
          requiredStaffByRatio,
          Math.ceil(count / RATIOS[ageGroup as AgeGroup])
        );
      }
    }
    return Math.max(0, requiredStaffByRatio);
  }
  
  return this.calculateStaffForMultipleGroups(children, totalChildren);
}

 calculateStaffForMultipleGroups(children: AgeLegend, totalChildren: number): number {
  const activeAgeGroups = Object.entries(children)
    .filter(([_, count]) => count > 0)
    .map(([group]) => group as AgeGroup);
  
    if (activeAgeGroups.length > 1) {
      // Mixed age group - use the ratio of the youngest child
      const ageOrder: AgeGroup[] = [
        'infant', 'young_toddler', 'older_toddler', 
        'preschool', 'young_school_age', 'older_school_age'
    ];
    const sortedAgeGroups = activeAgeGroups.sort((a, b) => {
      return ageOrder.indexOf(a) - ageOrder.indexOf(b);
  });
  const youngestAgeGroup = sortedAgeGroups[0];
   
    
    const ratio = RATIOS[youngestAgeGroup];
    const maxGroupSize = MAX_GROUP_SIZES[youngestAgeGroup];
    
    // Calculate number of full groups needed
    const numGroups = Math.ceil(totalChildren / maxGroupSize);
    
    // Calculate staff needed based on the youngest child's ratio
    let staffNeeded = 0;
    let remainingChildren = totalChildren;
    
    for (let i = 0; i < numGroups; i++) {
      const childrenInThisGroup = Math.min(remainingChildren, maxGroupSize);
      staffNeeded += Math.ceil(childrenInThisGroup / ratio);
      remainingChildren -= childrenInThisGroup;
    }
    
    return staffNeeded;
  } else if (activeAgeGroups.length === 1) {
    // Single age group
    const ageGroup = activeAgeGroups[0];
    const count = children[ageGroup];
    const ratio = RATIOS[ageGroup];
    const maxGroupSize = MAX_GROUP_SIZES[ageGroup];
    
    // Calculate number of full groups needed
    const numGroups = Math.ceil(count / maxGroupSize);
    
    // Calculate staff needed
    let staffNeeded = 0;
    let remainingChildren = count;
    
    for (let i = 0; i < numGroups; i++) {
      const childrenInThisGroup = Math.min(remainingChildren, maxGroupSize);
      staffNeeded += Math.ceil(childrenInThisGroup / ratio);
      remainingChildren -= childrenInThisGroup;
    }
    
    return staffNeeded;
  } else {
    // No children
    return 0;
  }
}
  
  // Customizable Area End
}
