import React from "react";
import {  Typography, List, ListItem, ListItemText, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface LocationData {
  id: number;
  title: string;
  address: string;
  latitude: number;
  longitude: number;
}

interface DayData {
  id: number;
  day: string;
  start_time: string;
  end_time: string;
  booked_slot_id: number;
  slot_time_duration: number | null;
  created_at: string;
  updated_at: string;
  par_hours_amount: number;
}

interface BookedSlotAttributes {
  id: number;
  location_data: LocationData;
  days_data: DayData[];
}

export interface BookedSlotProps {
  data: { attributes: BookedSlotAttributes }[] | null;
}

const TimeSlothCard: React.FC<BookedSlotProps> = ({ data }) => {
  if (!data || data.length === 0 || !data[0]?.attributes) {
    return <Typography>No data available</Typography>;
  }

  const bookedSlot = data[0]?.attributes;
  const { location_data, days_data } = bookedSlot;

  return (
    <Accordion sx={{ boxShadow: 3, mx: 0 }}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}> 
      <Typography variant="h6">Opening times</Typography>
    </AccordionSummary>
    <AccordionDetails>
        <List sx={{p:0}}>
          {days_data?.length > 0 ? (
            days_data.map((slot) => (
              <ListItem key={slot.id} divider sx={{display:'flex', flexDirection:'column', alignItems:'start',p:0}}>
                <ListItemText sx={{color:"#f1544b"}}
                    primary={`${slot.day}:`}
                  />
                  <ListItemText
                    primary={`${new Date(slot.start_time).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true })} - ${new Date(slot.end_time).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true })}`}
                  />
                </ListItem>
            ))
          ) : (
            <Typography>No slots available</Typography>
          )}
        </List>
     
    </AccordionDetails>
    </Accordion>
  );
};
export default TimeSlothCard;
